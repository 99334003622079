<template>
  <div class="login-page admin-page">
    <div class="container">
      <AdminHeader />

      <h2 class="heading">Anmeldung</h2>

      <v-form
        class="loginForm"
        ref="loginForm"
        v-model="valid"
        @submit="login"
        @keyup.native.enter="login"
      >
        <v-text-field
          class="email input"
          ref="email"
          outlined
          label="E-Mail"
          placeholder="max.mustermann"
          v-model="loginForm.email"
          :rules="[rules.required]"
          :error-messages="emailSignInValidation"
          @input="emailSignInValidation = ''"
          hint="Gib deinen IServ Nutzernamen ein"
          tabindex="1"
          suffix="@franz…asium.eu"
        ></v-text-field>
        <v-text-field
          class="password input"
          ref="password"
          outlined
          label="Passwort"
          v-model="loginForm.password"
          :rules="[rules.required]"
          :error-messages="passwordSignInValidation"
          @input="passwordSignInValidation = ''"
          :type="passwordVisible ? 'text' : 'password'"
          tabindex="2"
          :append-icon="passwordVisible ? 'visibility_off' : 'visibility'"
          @click:append="passwordVisible = !passwordVisible"
        ></v-text-field>

        <v-btn
          class="submit"
          color="primary"
          elevation="0"
          :disabled="!valid"
          :loading="loading"
          @click="login"
        >
          Anmelden
        </v-btn>
      </v-form>
    </div>

    <v-dialog v-model="error.active" persistent max-width="500">
      <v-card>
        <template v-if="error.code == 'unknown'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Anmelden aufgetreten
          </v-card-title>
          <v-card-text>
            Als wir versucht haben ich mit deinen angegebenen Daten anzumelden,
            ist ein unerwarteter Feher aufgetreten.<br />
            Fehlercode: {{ error.catched }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="error = { active: false, code: '', catched: null }"
              >OK</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { auth } from '@/firebase';
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'Login',
  components: { AdminHeader },
  data() {
    return {
      valid: false,
      loginForm: {
        email: '',
        password: '',
      },
      passwordVisible: false,
      rules: {
        required: (value) =>
          (!!String(value).trim().length &&
            (typeof value !== 'number' || !isNaN(value))) ||
          'Dies ist ein Pflichtfeld',
        email: (value) =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<script>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ) || 'E-Mail inkorrekt',
      },
      loading: false,
      error: {
        active: false,
        code: '',
        catched: null,
      },
      emailSignInValidation: '',
      passwordSignInValidation: '',
    };
  },
  methods: {
    async login() {
      let valid = this.$refs.loginForm.validate();
      if (!valid) return;

      this.loading = true;
      try {
        let userCredentials = await auth.signInWithEmailAndPassword(
          this.loginForm.email + '@franz-haniel-gymnasium.eu',
          this.loginForm.password
        );
        if (!userCredentials) return;

        let redirect = this.$route.query.redirect;
        if (redirect) {
          return this.$router.replace({
            path: redirect,
          });
        }
        while (!this.$userProfile)
          await new Promise((resolve) => setTimeout(resolve, 10));

        if (this.$userProfile.admin)
          return this.$router.replace({ path: '/admin' });
        if (this.$userProfile.canDebit.length)
          return this.$router.replace({ path: '/d' });

        return this.$router.replace({ path: '/' });
      } catch (error) {
        let emailField = this.$refs.email;
        let passwordField = this.$refs.password;

        if (emailField && passwordField) {
          if (error.code === 'auth/invalid-email') {
            this.emailSignInValidation = 'E-Mail inkorrekt';
            return;
          }
          if (error.code === 'auth/user-disabled') {
            this.emailSignInValidation = 'Nutzer deaktiviert';
            return;
          }
          if (error.code === 'auth/user-not-found') {
            this.emailSignInValidation =
              'Kein Nutzer mit dieser E-Mail gefunden';
            return;
          }
          if (error.code === 'auth/wrong-password') {
            this.passwordSignInValidation = 'Falsches Passwort';
            return;
          }
        }

        this.error = {
          active: true,
          code: 'unknown',
          catched: error,
        };
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/admin/admin.scss';

.login-page {
  .container {
    max-width: 500px;

    .heading {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
    }

    .loginForm {
      margin: 24px 0 0 0;
    }
  }
}
</style>
